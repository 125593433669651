import React, { createContext, useReducer, useEffect } from "react";
import { mutateMany } from "swr-mutate-many";
import getFirebase from "../modules/firebase";

const initialState = {
  // isInitializing: false,
  // isSigningUp: false,
  // signUpError: null,
  // isSigningIn: false,
  isAuthenticated: null,
  user: null,
  token: null
};
const Context = createContext();
const firebase = getFirebase();
const firebaseAuth = firebase.auth();

/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} haystack the array to search.
 * @param {array} arr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 * @see https://stackoverflow.com/a/25926600/5059156
 */
function findOne(haystack, arr) {
  if (haystack) return arr.some((v) => haystack.includes(v));
}

function reducer(state, action) {
  switch (action.type) {
    case "SET_AUTHENTICATED": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        // TODO remove temporal fake
        // token: action.payload.token
        token: { claims: { roles: [action.payload.roles] } }
      };
    }
    case "SET_UNAUTHENTICATED": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null
      };
    }
    default: {
      throw new Error(`Unknown case "${action.type}" in authentication`);
    }
  }
}

function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * @description check if the current user role is in the allowed roles
   * @param {array} allowed the array of allowed roles.
   * @return {boolean} true|false if haystack contains at least one item from arr.
   */
  function checkRole(allowed) {
    return findOne(allowed, state.token.claims.roles);
  }

  // async function signUp({ email, password }) {
  //   console.log("sign up in authentication", email, password)

  //   dispatch({ type: "SIGN_UP_BEGIN" });

  //   // await new Promise(resolve => setTimeout(resolve, 5000))

  //   try {
  //     await firebaseAuth.createUserWithEmailAndPassword(email, password);
  //   } catch (error) {
  //     if (error.code === "auth/email-already-in-use") {
  //       return dispatch({ type: "SIGN_UP_ERROR", payload: "emailAlreadyInUse" });
  //     }

  //     console.log("TODO handle error in authentication", error)

  //     return null;
  //   }

  //   dispatch({ type: "SIGN_UP_SUCCESS" });
  // }

  // async function signIn({ email, password }) {
  //   console.log("sign in in authentication", email, password)

  //   dispatch({ type: "SIGN_IN_BEGIN" });

  //   // await new Promise(resolve => setTimeout(resolve, 5000))

  //   try {
  //     await firebaseAuth.signInWithEmailAndPassword(email, password);
  //   } catch (error) {
  //     console.log("TODO handle error in authentication")
  //     return null;
  //   }

  //   dispatch({ type: "SIGN_IN_SUCCESS" });
  // }

  // async function signOut() {
  //   try {
  //     firebaseAuth.signOut();
  //   } catch (error) {
  //     console.log("TODO handle sign out error in authentication", error);
  //   }
  // }

  async function handleAuthStateChanged(user) {
    // console.log("handle auth state change in authentication", user);

    // let localUser;
    // let token;
    if (user) {
      // https://firebase.google.com/docs/reference/js/firebase.User#getToken
      // token = await user.getIdToken();
      // // console.log("TODO token in authentication", token)
      // // const token = await firebase.auth().currentUser
      // //   console.log("token", token);
      // localUser = {
      //   uid: user.uid,
      //   name: user.displayName,
      //   email: user.email,
      //   photo: user.photoURL,
      //   emailVerified: user.emailVerified
      // };
      const { claims } = await user.getIdTokenResult();

      mutateMany("/me", null);

      return dispatch({
        type: "SET_AUTHENTICATED",
        payload: { user, roles: claims["0"] }
      });
    }

    dispatch({ type: "SET_UNAUTHENTICATED" });

    // if (user) {
    //   if (!state.isInitialized) {
    //     dispatch({
    //       type: "INITIALIZE_SUCCESS",
    //       payload: { user }
    //     });
    //   }

    //   console.log("User is signed in.");
    // } else {
    //   dispatch({ type: "INITIALIZE_BEGIN" });

    //   console.log("No user is signed in.");

    //   await new Promise(resolve => setTimeout(resolve, 5000));

    //   firebase.auth().signInAnonymously();
    // }
  }

  // useEffect(() => {
  //   if (!firebase) {
  //     console.log("initialize begin in authentication");
  //     // dispatch({ type: "INITIALIZE_BEGIN" });
  //   } else {
  //     console.log("initialize success in authentication");
  //     // dispatch({ type: "INITIALIZE_SUCCESS" });

  //     firebaseAuth = firebase.auth();
  //     // https://firebase.google.com/docs/auth/web/manage-users
  //     firebaseAuth.onAuthStateChanged(handleAuthStateChanged);
  //   }
  //  }, [firebase]);
  useEffect(() => {
    // https://firebase.google.com/docs/auth/web/manage-users
    firebaseAuth.onAuthStateChanged(handleAuthStateChanged);
  }, []);

  // async function initialize() {
  //   dispatch({ type: "INITIALIZE_BEGIN" });

  //   // reset the last settings for debug
  //   // reset();

  //   // await new Promise((resolve) => setTimeout(resolve, 10000));

  //   dispatch({
  //     type: "INITIALIZE_SUCCESS",
  //     payload: {
  //       shouldBeCollected: await DidomiWrapper.shouldConsentBeCollected()
  //     }
  //   });
  // }

  return (
    <Context.Provider value={{ state, checkRole }}>{children}</Context.Provider>
  );
}

function useHook() {
  const context = React.useContext(Context);

  if (context === undefined) {
    throw new Error("useHook must be used within a Provider");
  }

  return context;
}

export const AuthenticationProvider = Provider;
export const useAuthentication = useHook;
