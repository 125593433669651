import React, { useState, useEffect } from "react";
import { useAuthentication } from "../context/authentication";
import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ children, roles, ...rest }) {
  const [isLoading, setIsLoading] = useState(true);
  const {
    state: { isAuthenticated },
    checkRole
  } = useAuthentication();

  useEffect(() => {
    if (isAuthenticated !== null) {
      // console.log(
      //   "is authenticated loaded in private route",
      //   roles,
      //   token.claims.roles
      // );
      setIsLoading(false);
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated && checkRole(roles) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
