import React, { Suspense, lazy } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthenticationProvider } from "../context/authentication";
import PrivateRoute from "./private-route";
import Modal from "react-modal";
import { SWRConfig } from "swr";
import getFirebase from "../modules/firebase";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "../libraries/i18n";

const firebase = getFirebase();
const firebaseAuth = firebase.auth();

registerLocale("es", es);
setDefaultLocale("es");

// https://blog.logrocket.com/speed-up-react-app-dynamic-imports-route-centric-code-splitting/
const Home = lazy(() => import("../screens/home"));
const SignIn = lazy(() => import("../screens/sign-in"));
const SignUp = lazy(() => import("../screens/sign-up"));
const Competition = lazy(() => import("../screens/competition"));
const Competitions = lazy(() => import("../screens/competitions"));
const CompetitionSeasons = lazy(() => import("../screens/competition-seasons"));
const CompetitionMatches = lazy(() => import("../screens/competition-matches"));
const CompetitionPairings = lazy(() =>
  import("../screens/competition-pairings")
);
const CompetitionGroupMatch = lazy(() =>
  import("../screens/competition-group-match")
);
const EditCompetitionGroupMatchParticipants = lazy(() =>
  import("../screens/edit-competition-group-match-participants")
);
const EditCompetitionGroupMatchDetails = lazy(() =>
  import("../screens/edit-competition-group-match-details")
);
const EditCompetitionGroupMatchSubstitutions = lazy(() =>
  import("../screens/edit-competition-group-match-substitutions")
);
const EditCompetitionGroupMatchScores = lazy(() =>
  import("../screens/edit-competition-group-match-scores")
);
const EditCompetitionGroupMatchBannsLocal = lazy(() =>
  import("../screens/edit-competition-group-match-banns-local")
);
const EditCompetitionGroupMatchBannsVisitor = lazy(() =>
  import("../screens/edit-competition-group-match-banns-visitor")
);
// const EditCompetitionGroupMatch = lazy(() =>
//   import("../screens/edit-competition-group-match")
// );
const CompetitionGroupPairingEdit = lazy(() =>
  import("../screens/competition-group-pairing-edit")
);
const CompetitionGroupPairing = lazy(() =>
  import("../screens/competition-group-pairing")
);
const CreateCompetitionGroup = lazy(() =>
  import("../screens/create-competition-group")
);
const EditCompetitionGroup = lazy(() =>
  import("../screens/edit-competition-group")
);
const EditCompetitionGroupReferee = lazy(() =>
  import("../screens/edit-competition-group-referee")
);
const Group = lazy(() => import("../screens/group"));
const Groups = lazy(() => import("../screens/groups"));
const EditCompetitionTeam = lazy(() =>
  import("../screens/edit-competition-team")
);
const EditCompetitionTeamArena = lazy(() =>
  import("../screens/edit-competition-team-arena")
);
const CompetitionTeamArena = lazy(() =>
  import("../screens/competition-team-arena")
);
const CompetitionTeam = lazy(() => import("../screens/competition-team"));
// const CompetitionTeams = lazy(() => import("../screens/competition-teams"));
// const CompetitionReferees = lazy(() =>
//   import("../screens/competition-referees")
// );
const CompetitionReferee = lazy(() => import("../screens/competition-referee"));
const EditCompetitionReferee = lazy(() =>
  import("../screens/edit-competition-referee")
);
const CompetitionGroupReferee = lazy(() =>
  import("../screens/competition-group-referee")
);
// const CompetitionArenas = lazy(() => import("../screens/competition-arenas"));
// const CompetitionSettings = lazy(() =>
//   import("../screens/competition-settings")
// );
const EditCompetitionDetailsSettings = lazy(() =>
  import("../screens/edit-competition-details-settings")
);
const EditCompetitionMatchesSettings = lazy(() =>
  import("../screens/edit-competition-matches-settings")
);
const EditCompetitionAddSchedule = lazy(() =>
  import("../screens/edit-competition-add-schedule")
);
const EditCompetitionPricesSettings = lazy(() =>
  import("../screens/edit-competition-prices-settings")
);
const EditCompetitionRulesSettings = lazy(() =>
  import("../screens/edit-competition-rules-settings")
);
const CreateCompetitionGroupSchedule = lazy(() =>
  import("../screens/create-competition-group-schedule")
);
const CreateTeam = lazy(() => import("../screens/create-team"));
const EditTeam = lazy(() => import("../screens/edit-team"));
const Teams = lazy(() => import("../screens/teams"));
const TeamArena = lazy(() => import("../screens/team-arena"));
const EditTeamDelegate = lazy(() => import("../screens/edit-team-delegate"));
const TeamDelegate = lazy(() => import("../screens/team-delegate"));
const EditTeamArena = lazy(() => import("../screens/edit-team-arena"));
const Team = lazy(() => import("../screens/team"));
const CreatePlayer = lazy(() => import("../screens/create-player"));
const EditPlayer = lazy(() => import("../screens/edit-player"));
const Players = lazy(() => import("../screens/players"));
const Player = lazy(() => import("../screens/player"));
const Referee = lazy(() => import("../screens/referee"));
const CreateReferee = lazy(() => import("../screens/create-referee"));
const EditReferee = lazy(() => import("../screens/edit-referee"));
const Referees = lazy(() => import("../screens/referees"));
const Arena = lazy(() => import("../screens/arena"));
const CreateArena = lazy(() => import("../screens/create-arena"));
const EditArena = lazy(() => import("../screens/edit-arena"));
const Arenas = lazy(() => import("../screens/arenas"));
const Notifications = lazy(() => import("../screens/notifications"));
const Notification = lazy(() => import("../screens/notification"));
const Settings = lazy(() => import("../screens/settings"));
const ProfileEdit = lazy(() => import("../screens/profile-edit"));
const Profile = lazy(() => import("../screens/profile"));
const NotFound = lazy(() => import("../screens/not-found"));
// const TeamPlayer = lazy(() => import("../screens/team-player"));
// const EditTeamPlayer = lazy(() => import("../screens/edit-team-player"));
const Scorers = lazy(() => import("../screens/scorers"));
const Finances = lazy(() => import("../screens/finances"));
// const FinancePlayer = lazy(() => import("../screens/finance-player"));
// const FinanceTeam = lazy(() => import("../screens/finance-team"));
const Sanctions = lazy(() => import("../screens/sanctions"));
const Sanction = lazy(() => import("../screens/sanction"));
const Classifications = lazy(() => import("../screens/classifications"));
const ImportStep1 = lazy(() => import("../screens/import-step-1"));
const ImportStep2 = lazy(() => import("../screens/import-step-2"));
const ImportStep3 = lazy(() => import("../screens/import-step-3"));
const Seasons = lazy(() => import("../screens/seasons"));
const CreateSeason1 = lazy(() => import("../screens/create-season-1"));
const CreateSeason2 = lazy(() => import("../screens/create-season-2"));
const CreateSeason3 = lazy(() => import("../screens/create-season-3"));
const CreateSeason4 = lazy(() => import("../screens/create-season-4"));
const EditSeason = lazy(() => import("../screens/edit-season"));
const Season = lazy(() => import("../screens/season"));
const MatchesDay = lazy(() => import("../screens/matches-day"));
const RefereeMatches = lazy(() => import("../screens/referee-matches"));
const EditCompetitionAddExcludingDays = lazy(() =>
  import("../screens/edit-competition-add-excluding-days")
);
const Users = lazy(() => import("../screens/users"));
const Receipt = lazy(() => import("../screens/receipt"));
const CompetitionGroupCreateManuallyMatches = lazy(() =>
  import("../screens/create-manually-matches")
);

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Application() {
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        refreshInterval: null,
        fetcher: async (resource, options = {}) => {
          const response = await fetch(`${process.env.API_URL}${resource}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await firebaseAuth.currentUser.getIdToken()}`
            },
            ...options
          });

          const jsonResponse = await response.json();

          // TODO if error code === 401, go to sign in and set redirect property to return to current page

          if (Array.isArray(jsonResponse)) {
            return {
              totalCount: parseInt(response.headers.get("X-Total-Count"), 10),
              entities: jsonResponse
            };
          }

          return jsonResponse;
        }
      }}
    >
      <HelmetProvider>
        <AuthenticationProvider>
          <Router>
            <Suspense fallback={<div>Page is Loading...</div>}>
              <Switch>
                <PrivateRoute
                  path="/"
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                  exact
                >
                  <Home />
                </PrivateRoute>
                <Route
                  path={"/sign-in"}
                  // roles={["", "delegate", "referee"]}
                >
                  <SignIn />
                </Route>
                <Route
                  path={"/sign-up"}
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                >
                  <SignUp />
                </Route>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/settings/edit-details"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditCompetitionDetailsSettings />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/settings/edit-matches"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditCompetitionMatchesSettings />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/settings/add-schedule"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditCompetitionAddSchedule />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/settings/add-excluding-days"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditCompetitionAddExcludingDays />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/settings/edit-prices"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditCompetitionPricesSettings />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/settings/edit-rules"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditCompetitionRulesSettings />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/matches/:matchId/edit-details"
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                >
                  <EditCompetitionGroupMatchDetails />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/matches/:matchId/teams/:teamId/edit-substitutions"
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                >
                  <EditCompetitionGroupMatchSubstitutions />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/matches/:matchId/teams/:teamId/edit-scores"
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                >
                  <EditCompetitionGroupMatchScores />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/matches/:matchId/edit-banns-local"
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                >
                  <EditCompetitionGroupMatchBannsLocal />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/matches/:matchId/edit-banns-visitor"
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                >
                  <EditCompetitionGroupMatchBannsVisitor />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/matches/:matchId/teams/:teamId/edit-participants"
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                >
                  <EditCompetitionGroupMatchParticipants />
                </PrivateRoute>
                {/* <PrivateRoute path="/competitions/:competitionId/groups/:groupId/matches/:matchId/edit">
                  <EditCompetitionGroupMatch />
                </PrivateRoute> */}
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/matches/:matchId"
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                >
                  <CompetitionGroupMatch />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/pairings/:pairingId/edit"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CompetitionGroupPairingEdit />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/pairings/:pairingId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CompetitionGroupPairing />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/create-schedule"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CreateCompetitionGroupSchedule />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/referees/:refereeId/edit"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditCompetitionGroupReferee />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/referees/:refereeId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CompetitionGroupReferee />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/create-matches"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CompetitionGroupCreateManuallyMatches />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/create-competition-group"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CreateCompetitionGroup />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId/edit"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditCompetitionGroup />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups/:groupId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Group />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/groups"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Groups />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/teams/:teamId/arenas/:arenaId/edit"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditCompetitionTeamArena />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/teams/:teamId/arenas/:arenaId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CompetitionTeamArena />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/teams/:teamId/edit"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditCompetitionTeam />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/teams/:teamId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CompetitionTeam />
                </PrivateRoute>
                {/* <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/teams"
                  roles={["Competition Owner", "Delegate"]}
                >
                  <CompetitionTeams />
                </PrivateRoute> */}
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/referees/:refereeId/edit"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditCompetitionReferee />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/referees/:refereeId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CompetitionReferee />
                </PrivateRoute>
                {/* <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/referees"
                  roles={["Competition Owner", "Delegate"]}
                >
                  <CompetitionReferees />
                </PrivateRoute> */}
                {/* <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/arenas"
                  roles={["Competition Owner", "Delegate"]}
                >
                  <CompetitionArenas />
                </PrivateRoute> */}
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/matches"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CompetitionMatches />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/pairings"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CompetitionPairings />
                </PrivateRoute>
                {/* <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/settings"
                  roles={["Competition Owner", "Delegate"]}
                >
                  <CompetitionSettings />
                </PrivateRoute> */}
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/seasons"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CompetitionSeasons />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/classifications"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Classifications />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/scorers"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Scorers />
                </PrivateRoute>
                {/* <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/finance-player/:playerId/team/:teamId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <FinancePlayer />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/finance-team/:teamId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <FinanceTeam />
                </PrivateRoute> */}
                <PrivateRoute
                  path="/finances"
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                >
                  <Finances />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/sanctions/:sanctionId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Sanction />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId/sanctions"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Sanctions />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions/:competitionId/seasons/:seasonId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Competition />
                </PrivateRoute>
                <PrivateRoute
                  path="/competitions"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Competitions />
                </PrivateRoute>
                <PrivateRoute
                  path="/teams/create-team"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CreateTeam />
                </PrivateRoute>
                <PrivateRoute
                  path="/seasons/:seasonId/teams/:teamId/delegates/:delegateId/edit"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditTeamDelegate />
                </PrivateRoute>
                <PrivateRoute
                  path="/seasons/:seasonId/teams/:teamId/delegates/:delegateId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <TeamDelegate />
                </PrivateRoute>
                <PrivateRoute
                  path="/seasons/:seasonId/teams/:teamId/arenas/:arenaId/edit"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditTeamArena />
                </PrivateRoute>
                <PrivateRoute
                  path="/seasons/:seasonId/teams/:teamId/arenas/:arenaId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <TeamArena />
                </PrivateRoute>
                {/* <PrivateRoute path="/teams/:teamId/players/:playerId/edit">
                  <EditTeamPlayer />
                </PrivateRoute>
                <PrivateRoute path="/teams/:teamId/players/:playerId">
                  <TeamPlayer />
                </PrivateRoute> */}
                <PrivateRoute
                  path="/teams"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Teams />
                </PrivateRoute>
                <PrivateRoute
                  path="/seasons/:seasonId/teams/:teamId/delegates/:delegateId/edit"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditTeamDelegate />
                </PrivateRoute>
                <PrivateRoute
                  path="/seasons/:seasonId/teams/:teamId/edit"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditTeam />
                </PrivateRoute>
                <PrivateRoute
                  path="/seasons/:seasonId/teams/:teamId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Team />
                </PrivateRoute>
                <PrivateRoute
                  path="/players/create-player"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CreatePlayer />
                </PrivateRoute>
                <PrivateRoute
                  path="/players"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Players />
                </PrivateRoute>
                <PrivateRoute
                  path="/seasons/:seasonId/players/:playerId/edit"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditPlayer />
                </PrivateRoute>
                <PrivateRoute
                  path="/seasons/:seasonId/players/:playerId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Player />
                </PrivateRoute>
                <PrivateRoute
                  path="/referees/create-referee"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CreateReferee />
                </PrivateRoute>
                <PrivateRoute
                  path="/seasons/:seasonId/referees/:refereeId/receipts/:receiptId"
                  roles={["Competition Owner"]}
                >
                  <Receipt />
                </PrivateRoute>
                <PrivateRoute
                  path="/referees/:refereeId/receipts/:receiptId"
                  roles={["Referee"]}
                >
                  <Receipt />
                </PrivateRoute>
                <PrivateRoute
                  path="/seasons/:seasonId/referees/:refereeId/edit"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditReferee />
                </PrivateRoute>
                <PrivateRoute
                  path="/seasons/:seasonId/referees/:refereeId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Referee />
                </PrivateRoute>
                <PrivateRoute
                  path="/referees"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Referees />
                </PrivateRoute>
                <PrivateRoute
                  path="/arenas/create-arena"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CreateArena />
                </PrivateRoute>
                <PrivateRoute
                  path="/arenas/:arenaId/matches/:day"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <MatchesDay />
                </PrivateRoute>
                <PrivateRoute
                  path="/arenas/:arenaId/edit"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditArena />
                </PrivateRoute>
                <PrivateRoute
                  path="/arenas/:arenaId"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Arena />
                </PrivateRoute>
                <PrivateRoute
                  path="/arenas"
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Arenas />
                </PrivateRoute>
                <PrivateRoute
                  path="/notifications/:notificationId"
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                >
                  <Notification />
                </PrivateRoute>
                <PrivateRoute
                  path="/notifications"
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                >
                  <Notifications />
                </PrivateRoute>

                <PrivateRoute
                  path={"/settings"}
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Settings />
                </PrivateRoute>
                <PrivateRoute
                  path={"/profile/edit"}
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                >
                  <ProfileEdit />
                </PrivateRoute>
                <PrivateRoute
                  path={"/profile"}
                  roles={["Competition Owner", "Team delegate", "Referee"]}
                >
                  <Profile />
                </PrivateRoute>
                <PrivateRoute
                  path={"/import-step-1"}
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <ImportStep1 />
                </PrivateRoute>
                <PrivateRoute
                  path={"/import-step-2"}
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <ImportStep2 />
                </PrivateRoute>
                <PrivateRoute
                  path={"/import-step-3"}
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <ImportStep3 />
                </PrivateRoute>
                <PrivateRoute
                  path={"/seasons/:seasonId/edit"}
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <EditSeason />
                </PrivateRoute>
                <PrivateRoute
                  path={"/seasons/:seasonId"}
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Season />
                </PrivateRoute>
                <PrivateRoute
                  path={"/seasons"}
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <Seasons />
                </PrivateRoute>
                <PrivateRoute
                  path={"/create-season-1"}
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CreateSeason1 />
                </PrivateRoute>
                <PrivateRoute
                  path={"/create-season-2"}
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CreateSeason2 />
                </PrivateRoute>
                <PrivateRoute
                  path={"/create-season-3"}
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CreateSeason3 />
                </PrivateRoute>
                <PrivateRoute
                  path={"/create-season-4"}
                  roles={["Competition Owner", "Team delegate"]}
                >
                  <CreateSeason4 />
                </PrivateRoute>
                <PrivateRoute path={"/referee-matches"} roles={["Referee"]}>
                  <RefereeMatches />
                </PrivateRoute>
                <PrivateRoute path={"/users"} roles={["Competition Owner"]}>
                  <Users />
                </PrivateRoute>
                {/* <PrivateRoute path={"/:competitionSlug"}>
                  <CompetitionRouter />
                </PrivateRoute> */}
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Suspense>
          </Router>
        </AuthenticationProvider>
      </HelmetProvider>
    </SWRConfig>
  );
}

export default Application;
