import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import en from "../locales/en.json";
// import es from "../locales/es.json";

// import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

// https://medium.com/@danduan/translating-react-apps-using-i18next-d2f78bc87314#0883

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ["competition-matches"],
    resources: {
      // en: { // translation is the default namespace
      //   translation: en
      // },
      // es: {
      //   translation: es
      // }
    },
    fallbackLng: "es",
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;